@import "@/assets/scss/style.scss";

.ant-layout {
    min-height: 100vh;
}

.dashboard-wrapper {
    height: 100%;
    position: relative;

    &__print-wrapper {
        @include flex-center;
        gap: 1rem;
    }

    &__edit-toggle-wrapper {
        @include flex-center;
    }

    .dashboard-title {
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        font-size: 1rem;
        margin: 2rem 0 3rem 0;

        h3 {
            color: rgb(51, 51, 51);
        }

        span {
            color: rgb(75, 75, 75);
            font-weight: bold;
        }
    }

    .dashboard-content-wrapper {
        background-color: $white-two;
    }

    &__top-bar {
        min-height: 64px;
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background-color: #fff;
        border-bottom: 1px solid rgb(233, 233, 233);
        z-index: 1111;
    }

    &__toggle-wrapper {
        @include flex-center;
    }

    &__toggle-label {
        margin-right: 0.5rem;
        font-weight: normal;
        color: $blue-6;
    }
}

.ant-table-placeholder {
    z-index: 0;
}

.ant-notification {
    z-index: 1200;
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}
