@import "@/assets/scss/style.scss";

.statistics-content__inner-wrap {
    padding: 1rem 4rem 4rem 4rem;

    @media (max-width: 1200px) {
        padding: 1rem 2rem 4rem 2rem;
    }

    @media (max-width: 768px) {
        padding: 1rem 1rem 2rem 1rem;
    }

    .statistics-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .drag-icon {
            font-size: 50px;
            color: $blue-6;
            position: absolute;
            @include absolute-center;
            z-index: 100;
            cursor: move;
        }
    }

    .content-item {
        padding: 0.8rem 0;
        margin: 0;
        min-width: 49%;
        min-height: 95px;
        height: 100%;
        position: relative;

        .border-wrapper {
            padding: 1rem;
            border: 2px solid #d5d8f9;
            width: 100%;
            max-width: 100%;
        }

        .edit-border {
            border: 2px dashed blue;
        }
    }

    .edit-mode {
        cursor: move;
    }
}

.ant-table {
    font-size: inherit;
    color: inherit;
    overflow-x: auto;
}

.ant-table-content {
    overflow-y: auto;
    max-height: 600px;
}

.ant-table-column-title {
    font-weight: bold;
    white-space: nowrap;
}

.cell-padding--small {
    padding: 6px !important;
}

.cell-padding--medium {
    padding: 10px !important;
}

.cell-padding--large {
    padding: 16px !important;
}
