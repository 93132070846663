@import "@/assets/scss/style.scss";

.apexcharts-legend {
    position: static;
    width: fit-content;
    padding: 0;
}

.apexcharts-xaxix-label {
}
