@import "@/assets/scss/style.scss";

.piechart {
    .piechartContainer {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;

        & > div {
            min-height: 0;
        }
    }

    .apexcharts-legend {
        margin-top: 1.5rem !important;
        right: 60px !important;
        top: 0 !important;
        position: static !important;
        width: fit-content !important;
        height: 75% !important;
        padding: 0 !important;
        margin-left: auto !important;
        overflow: auto !important;
    }

    .apexcharts-legend-series {
        @include flex-center;
        justify-content: flex-start;
        margin: 5px 0 5px 5px !important;
        // width: calc(100% + 20px);
    }

    .apexcharts-legend-text {
        min-width: 6rem;
        max-width: 8rem;
        word-break: break-all;
    }

    .apexcharts-legend-marker {
        margin-right: 0.7rem;
    }

    .legend-title {
        @include flex-center;
        justify-content: space-between;

        span {
            margin-left: 0.5rem;
        }
    }

    .legend-amount {
        font-weight: bold;
    }

    .apexcharts-toolbar {
    }
}
