@import "@/assets/scss/style.scss";

.matrix {
    width: 100%;

    [data-row-key='total'] {
        background-color: #fafafa;

        td {
            font-weight: bold;
            color: #000;
        }
    }

    .ant-table-column-title {
        font-weight: bold;
    }

    .border-wrapper {
        width: auto;
    }

    .matrix__total-row {
        display: flex;

        span {
            width: 33%;
        }
    }
}
