@import "@/assets/scss/style.scss";

.tasks-wrapper {
    margin-bottom: 2.5rem;
    .tasks__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .tasks__title {
            font-weight: bold;
            color: $grey-8;
            font-size: 14px;
            margin: 0;
        }
        .tasks__view-button {
            color: $blue-6;
            font-size: 14px;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .tasks__content {
        .tasks__field {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 18px;
            margin-bottom: 1rem;
            background-color: #fff;
            border: 1px solid $grey-4;
            border-radius: 10px;

            .time-stamp {
                @include flex-center;
                flex-direction: column;
                border-right: 2px solid $grey-4;
                padding-right: 2rem;
                .time {
                    font-weight: bold;
                    color: $grey-9;
                    font-size: 1.125rem;
                    margin: 0;
                }
                .date {
                    color: $grey-9;
                    font-size: 0.875rem;
                    margin: 0;
                }
            }
            .client {
                @include flex-center;
                flex-direction: column;
                align-items: flex-start;
                margin: 0 auto 0 2rem;
                .name {
                    color: #000;
                    font-size: 0.875rem;
                    margin-bottom: 5px;
                }
                .status {
                    text-align: center;
                    font-size: 0.75rem;
                    color: $blue-6;
                    background-color: $blue-1;
                    border: 1px solid #91d5ff;
                    border-radius: 2px;
                    padding: 3px;
                    width: fit-content;
                }
            }
            .field-icon {
                font-size: 20px;
                cursor: pointer;
                color: $blue-6;
            }
        }
    }
}
