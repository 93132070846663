@import "@/assets/scss/style.scss";

.table-report {
    width: 100%;

    .ant-table-wrapper {
        // overflow: auto;
    }

    .ant-table-column-title {
        font-weight: bold;
    }

    .border-wrapper {
        width: fit-content;
    }
}
