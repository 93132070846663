@import "@/assets/scss/style.scss";

.projects-overview__field {
    @include flex-center;
    cursor: pointer;
    // justify-content: flex-start;

    // width: 25%;

    .projects-overview__icon-group {
        // width: max-content;

        .projects-overview__icon-primary {
            font-size: 20px;
            color: $roltek-blue;

            svg {
                height: inherit;
                width: inherit;
            }
        }
        .projects-overview__icon-secondary {
            font-size: 14px;
            margin-left: -12px;
            color: $roltek-blue;
            background-color: #fff;
        }
    }

    .projects-overview__status {
        margin: 0 9px;
        font-size: 0.875rem;
        color: $grey-8;
    }

    .projects-overview__count {
        font-size: 0.75rem;
        color: $grey-9;
        padding: 10px;
        border-radius: 5px;
        background-color: #f5f5f5;
    }
}
