@import "@/assets/scss/style.scss";

.chart__header {
    margin-bottom: 1rem;
}

.news-cms-wrapper {
    // margin: 5rem auto;

    @media (max-width: 1400px) {
    }

    @media (max-width: 1100px) {
    }

    @media (max-width: 800px) {
    }

    .news__top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        // margin: 0.5rem;

        @media (max-height: 650px) {
            flex-direction: column;
        }

        button,
        span {
            cursor: pointer;
            font-size: 1rem;
        }

        .news-cms__span-wrapper {
            @include flex-center;
            margin-bottom: 2rem;

            span {
                font-size: 0.75rem;
            }

            .divider {
                margin: 0 0.5rem;
                height: 20px;
                width: 1px;
                background-color: #000;
            }

            .is-active {
                color: #000;
                border-bottom: 1px solid #000;
            }
        }
    }
    .news-cms-content {
        .news-table-wrapper {
            overflow: auto;

            .ant-table-wrapper {
                min-width: max-content;
            }
        }
    }

    .delete-icon {
        font-size: 20px;
        color: red;
    }
}

.news-modal {
    max-height: 1200px;
    overflow-y: auto;
}

.statistics-content .news .border-wrapper {
    padding: 1.5rem;
    border: 1px solid $grey-4;
    border-radius: 5px;
}

.statistics-content .news .edit-border {
    border: 2px dashed blue;
}
