@import "@/assets/scss/style.scss";

.chart-header {
    @include flex-center;
    justify-content: flex-start;
    margin-bottom: 2rem;

    .chart-header__edit-icons {
        margin-left: auto;
    }

    .chart-header__download-icon {
        font-size: 1.5rem;
        cursor: pointer;
        color: $blue-6;
    }

    .chart__title {
        font-size: 0.7rem;
        font-weight: bold;
        color: $grey-9;
        margin: 0 1rem;
    }

    .chart__subtitle {
        font-size: 0.65rem;
        color: $grey-7;
    }

    .chart-header__edit-icons {
        @include flex-center;
    }

    .edit-icon {
        color: $blue-6;
        cursor: pointer;
        font-size: 20px;
    }

    .delete-icon {
        color: #ff6262;
        cursor: pointer;
        font-size: 20px;
        margin: 0 0.5rem 0 1rem;
    }

    &__extend-switch {
        @include flex-center;
    }

    &__toggle-label {
        margin-right: 0.5rem;
        font-weight: normal;
        color: $blue-6;
    }
}

.download-modal__item-wrapper {
    @include flex-center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    cursor: pointer;

    .download-modal__item-wrapper__icon {
        font-size: 1.5rem;
        margin-right: 1rem;
        color: $blue-6;
    }

    .download-modal__item-wrapper__title {
    }
}
