@import "@/assets/scss/style.scss";

.projects-overview {
    width: 100%;

    .chart__header .delete-icon {
        margin-right: 0;
    }

    .chart__header .chart__subtitle {
        color: $blue-6;
        font-size: 0.875rem;
        cursor: pointer;
    }

    .projects-overview__data-wrapper {
        @include flex-center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
