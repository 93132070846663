@import "@/assets/scss/style.scss";

.comments-wrapper {
    .comments__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .comments__title {
            font-weight: bold;
            color: $grey-8;
            font-size: 14px;
            margin: 0;
        }
        .comments__view-button {
            color: $blue-6;
            font-size: 14px;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .comments__content {
        .comments__field {
            background-color: #fff;
            border-radius: 10px;
            padding: 18px;
            margin-bottom: 1rem;
        }

        .field__header {
            @include flex-center;
            justify-content: space-between;
            margin-bottom: 1rem;

            .field__info-wrapper {
                @include flex-center;
            }

            .field__title {
                font-weight: bold;
                font-size: 0.875rem;
                color: $grey-9;
            }

            .field__info {
                margin-right: 1rem;
                color: $grey-9;

                .field__info-title {
                }

                .field__id {
                    color: $grey-8;
                }
            }

            .field__icon {
                font-size: 20px;
                cursor: pointer;
                color: $blue-6;
            }
        }

        .field__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            background-color: $grey-2;
            border: 1px solid #f5f5f5;
            border-radius: 4px;
            padding: 8px 10px;

            .field__content__text {
                margin-bottom: 0.5rem;
                color: $grey-9;
                font-weight: medium;
                font-size: 0.875rem;
            }

            .field__content__info {
                font-size: 0.75rem;
                color: $grey-8;
            }
        }
    }
}
