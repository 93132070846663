@import "@/assets/scss/style.scss";

.statistics-sidebar-title {
    color: $grey-8;
    font-weight: bold;
}

.sidebar-card-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
}

.statistics-sidebar-wrapper {
    margin-bottom: 15px;
}

.statistics-sidebar-icon {
    color: #000;
}

.statistics__sidebar-edit {
    @include flex-center;
    flex-direction: column;
    width: 100%;

    @media screen and (max-width: 550px) {
        width: 80%;
    }

    .sidebar-edit__title {
        align-self: center;
    }

    .report-settings__item,
    .user-select__item {
        margin: 2rem 0;
        @include flex-center;
        flex-direction: column;
    }

    .statistics__sidebar-item {
        border: 2px solid #d5d8f9;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 2rem;
    }

    .report-settings__title,
    .user-select__title {
        text-align: center;
        font-weight: bold;
        font-size: 1.2rem;
        padding-bottom: 15px;
        border-bottom: 2px solid #d5d8f9;
        color: blue;
        width: 100%;
    }

    .report-settings__item-title,
    .user-select__item-title {
        font-weight: bold;
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
    }

    .user-select__wrapper {
        margin: 2rem 0 1rem 0;
    }

    .date-select__wrapper {
        width: 100%;
        margin: 1rem auto 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 1rem;

        .date-select__field {
            width: 100%;

            h2 {
                margin-bottom: 0.3rem;
                font-size: 0.9rem;
                font-weight: bold;
            }
        }
    }
}

.dashboard-wrapper .app-sidebar .ant-layout-sider {
    position: sticky;
    top: 4rem;
    height: 100vh;

    .ant-layout-sider-children {
        overflow: hidden auto;
    }
}
