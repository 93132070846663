@import "@/assets/scss/style.scss";

.total-report {
    @include flex-center;
    width: 100%;

    &__field-wrapper {
        @include flex-center;
        width: 100%;
    }

    .chart__header {
        margin-bottom: 1.5rem;
        margin-right: 0.5rem;
    }

    &__info-field {
        @include flex-center;
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        border: 2px solid #eff1ff;
        border-radius: 5px;
    }

    &__title {
        font-size: 1.15rem;
        font-weight: bold;
        color: $grey-9;

        &--font-small {
            font-size: 0.9rem;
        }

        &--font-large {
            font-size: 1.375rem;
        }
    }

    &__description {
        font-size: 1rem;
        color: $grey-9;

        &--font-small {
            font-size: 0.8rem;
        }

        &--font-large {
            font-size: 1.1rem;
        }
    }

    &__info-field:nth-of-type(2) {
        margin: 0 1rem;
    }
}
